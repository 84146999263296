import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

export const Crop = ({ src, cropperRef }: any) => {
  return (
    <Cropper
      src={src}
      style={{ height: '100%', width: '100%' }}
      dragMode="move"
      viewMode={1}
      aspectRatio={1}
      autoCropArea={1}
      guides={false}
      ref={cropperRef}
      center={false}
      background={false}
    />
  );
};
