import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import {
  FaSpotify,
  FaRegStopCircle,
  FaPlayCircle,
  FaBandcamp,
} from 'react-icons/fa';

export const Song = ({
  song,
  idx,
  playSong,
  playingSong,
  hoveredSong,
  hoverOverSong,
  style,
}: any) => {
  const { url, track, artist, album, img, id } = song;
  let firstColumn: any = <span>{idx || '#'}</span>;
  if (playingSong?.id === id) {
    if (hoveredSong === id) {
      firstColumn = <FaRegStopCircle style={{ color: 'rgb(180, 255, 58)' }} />;
    } else {
      firstColumn = (
        <FontAwesomeIcon icon={faVolumeHigh} beat style={{ marginLeft: -4 }} />
      );
    }
  } else if (hoveredSong === id) {
    firstColumn = <FaPlayCircle style={{ color: 'rgb(180, 255, 58)' }} />;
  }
  // <Knob value={value} onChange={(e) => setValue(e.value)} strokeWidth={5} />

  if (hoveredSong === id) {
    if (!style) {
      style = {};
    }
    style.filter = 'none';
  }
  const songWithIndex = { ...song, index: idx - 1 };
  return (
    <div
      key={id}
      className={`song grid ${playingSong?.id === id ? 'playing' : ''}`}
      style={{ margin: 0, ...style }}
      onClick={() => playSong(playingSong?.id === id ? null : songWithIndex)}
      onMouseEnter={() => hoverOverSong(id)}
      onMouseLeave={() => hoverOverSong(null)}
    >
      <div
        className="col-fixed flex align-items-center justify-content-start no-select glow"
        style={{
          width: 32,
        }}
        onClick={() => playSong(playingSong?.id === id ? null : songWithIndex)}
      >
        {firstColumn}
      </div>
      <div className="col-1 flex align-items-center justify-content-start no-select">
        {img ? (
          <img
            src={img}
            alt={`Album cover of ${track} by ${artist}`}
            style={{ height: 48, width: 48 }}
          />
        ) : (
          <span />
        )}
      </div>
      <div className="col-4 flex align-items-center justify-content-start">
        {track}
      </div>
      <div className="col flex align-items-center justify-content-start">
        {artist}
      </div>
      <div className="col flex align-items-center justify-content-start">
        {album}
      </div>
      <div className="col-1 flex align-items-center justify-content-end no-select">
        {url ? (
          <a
            onClick={(e) => e.stopPropagation()}
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{}}
          >
            <FaSpotify />
          </a>
        ) : (
          <span />
        )}
        <a
          onClick={(e) => e.stopPropagation()}
          href={`https://bandcamp.com/search?q=${track.replace(/ /g, '+')}+${artist.replace(/ /g, '+')}&item_type=t`}
          target="_blank"
          rel="noreferrer"
          style={{ padding: 16 }}
        >
          <FaBandcamp />
        </a>
      </div>
    </div>
  );
};
