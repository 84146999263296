// import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
// import oauth from 'axios-oauth-client';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Song } from './components/Song';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import { FileUpload } from 'primereact/fileupload';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
// import { rgbToHsl } from './utilities/rgbToHsl';
import { Crop } from './components/Crop';
import { Button } from 'primereact/button';
import { ReactCropperElement } from 'react-cropper';

// const authorizationEndpoint = 'https://accounts.spotify.com/authorize';
const tokenEndpoint = 'https://accounts.spotify.com/api/token';
// const scope = 'ugc-image-upload playlist-modify-public user-read-email';
const redirectUrl = 'http://localhost:3000';

const client_id = process.env.REACT_APP_CLIENT_ID as string;
// const clientSecret = process.env.REACT_APP_CLIENT_SECRET as string;
// const getClientCredentials = oauth.clientCredentials(
//   axios.create(),
//   tokenEndpoint,
//   clientId,
//   clientSecret
// );

const getToken = async (code: string) => {
  const code_verifier = localStorage.getItem('code_verifier');

  const response = await fetch(tokenEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      client_id,
      grant_type: 'authorization_code',
      code,
      redirect_uri: redirectUrl,
      code_verifier,
    } as any),
  });

  return await response.json();
};

export const Home = () => {
  const [user, setUser] = useState<any>(null);
  const [playingSong, playSong] = useState<any>(null);
  const [hoveredSong, hoverOverSong] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [pictureInfo, setPictureInfo] = useState<any>(null);
  const [unglitchedImg, setUnglitchedImg] = useState<any>(null);
  const [unglitchedImgDimensions, setUnglitchedImgDimensions] = useState<
    number[] | null
  >(null);
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [auth, setAuth] = useLocalStorage('auth', {
    access_token: null,
    refresh_token: null,
    expires_in: null,
    refresh_at: null,
  });

  const refreshToken = async () => {
    const response = await fetch(tokenEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: client_id,
        grant_type: 'refresh_token',
        refresh_token: auth.refresh_token,
      } as any),
    });

    return await response.json();
  };

  const refreshTokenClick = async () => {
    const newAuth = await refreshToken();
    const refresh_at = new Date(
      new Date().getTime() + newAuth.expires_in * 1000,
    );
    setAuth({ ...newAuth, refresh_at });
  };
  // const logoutClick = () => {
  //   localStorage.clear();
  //   window.location.href = redirectUrl;
  // };

  // const redirectToSpotifyAuthorize = async () => {
  //   const possible =
  //     'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   const randomValues = crypto.getRandomValues(new Uint8Array(64));
  //   const randomString = randomValues.reduce(
  //     (acc, x) => acc + possible[x % possible.length],
  //     '',
  //   );

  //   const code_verifier = randomString;
  //   const data = new TextEncoder().encode(code_verifier);
  //   const hashed = await crypto.subtle.digest('SHA-256', data);

  //   const code_challenge_base64 = btoa(
  //     String.fromCharCode(...new Uint8Array(hashed)),
  //   )
  //     .replace(/=/g, '')
  //     .replace(/\+/g, '-')
  //     .replace(/\//g, '_');

  //   window.localStorage.setItem('code_verifier', code_verifier);

  //   const authUrl = new URL(authorizationEndpoint);
  //   const params = {
  //     response_type: 'code',
  //     client_id: client_id,
  //     scope: scope,
  //     code_challenge_method: 'S256',
  //     code_challenge: code_challenge_base64,
  //     redirect_uri: redirectUrl,
  //   };

  //   authUrl.search = new URLSearchParams(params).toString();
  //   window.location.href = authUrl.toString();
  // };

  const getUserData = async (a = auth) => {
    const response = await fetch('https://api.spotify.com/v1/me', {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + a.access_token },
    });
    return await response.json();
  };

  const searchTracks = async (words: string[]) => {
    const response = await fetch(
      `https://f2xv5r8ih1.execute-api.us-east-1.amazonaws.com/prod/spotify?terms=${words.join(',')}`,
      {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + auth.access_token },
      },
    );
    return await response.json();
  };

  const [songs, setSongs] = useState([]);
  useEffect(() => {
    const args = new URLSearchParams(window.location.search);
    const code = args.get('code');

    if (code) {
      getToken(code).then(async (newAuth): Promise<void> => {
        const refresh_at = new Date(
          new Date().getTime() + newAuth.expires_in * 1000,
        );
        setAuth({ ...newAuth, refresh_at });

        const url = new URL(window.location.href);
        url.searchParams.delete('code');
        const updatedUrl = url.search ? url.href : url.href.replace('?', '');
        window.history.replaceState({}, document.title, updatedUrl);

        if (newAuth.access_token) {
          getUserData(newAuth).then((user) => setUser(user));
        } else {
          setUser(null);
        }
      });
    } else if (
      new Date().getTime() > new Date(auth.refresh_at || '').getTime()
    ) {
      refreshTokenClick();
    } else if (auth?.access_token && !user) {
      getUserData(auth).then((user) => setUser(user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, setAuth, setUser, user]);

  const { load, stop, play } = useGlobalAudioPlayer();
  // console.log('position', getPosition(), duration);
  useEffect(() => {
    if (playingSong?.preview) {
      load(playingSong.preview, {
        autoplay: true,
        html5: true,
        format: 'mp3',
        onstop: () => {},
        onend: () => {
          const newIndex = (playingSong.index + 1) % songs.length;
          playSong({ ...(songs[newIndex] as any), index: newIndex });
        },
      });
      play();
    } else {
      stop();
    }
  }, [playingSong, load, play, songs, stop]);

  const [decayedImage, setDecayedImage] = useState<any>(null);

  const upload = async () => {
    const cropper = cropperRef.current?.cropper;
    setLoading(true);
    cropper!.getCroppedCanvas().toBlob((blob) => {
      if (!blob) {
        throw new Error('invalid blob');
      }

      Promise.all([
        axios
          .post(
            'https://f2xv5r8ih1.execute-api.us-east-1.amazonaws.com/prod/detect',
            blob,
            { headers: { 'Content-Type': blob.type } },
          )
          .then(({ data }: { data: { labels: string[]; colors: any[] } }) => {
            setPictureInfo(data);
            setSearchTerms(data.labels);
          })
          .catch(console.error),
        axios
          .post(
            'https://f2xv5r8ih1.execute-api.us-east-1.amazonaws.com/prod/glitch',
            blob,
            { headers: { 'Content-Type': blob.type } },
          )
          .then(({ data }: { data: any }) => {
            setDecayedImage(data);
          })
          .catch(console.error),
      ]).then(() => setLoading(false));
    }, 'image/jpeg');
  };
  useEffect(() => {
    if (searchTerms?.length > 0) {
      setLoading(true);
      searchTracks(searchTerms)
        .then((results) => {
          setSongs(results.tracks);
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms]);
  const cropperRef = useRef<ReactCropperElement>(null);

  const mainColor = pictureInfo?.colors.img_colors[0].colorCode
    .replace(')', '')
    .replace('(', '');
  // console.log('mainColor', mainColor);
  return (
    <div className="page">
      {songs.length === 0 && !loading && !unglitchedImg && (
        <FileUpload
          name="bury"
          accept="image/*"
          maxFileSize={1000000}
          mode="basic"
          emptyTemplate={
            <p className="m-0">drag and drop an image here to upload</p>
          }
          onSelect={(e) => {
            const reader = new FileReader();
            const file = e.files[0];
            // console.log('filename', file.name.replace(/\.[^/.]+$/, ''));

            reader.readAsDataURL(file);
            reader.onload = (e) => {
              const image = new Image();
              image.src = e.target?.result as string;

              image.onload = function () {
                const img: any = this;
                const decoded = atob(img.src.split(',')[1]);
                const type = img.src
                  .split(',')[0]
                  .replace('data:', '')
                  .replace(';base64', '');

                var ab = new ArrayBuffer(decoded.length);
                var ia = new Uint8Array(ab);

                for (var i = 0; i < decoded.length; i++) {
                  ia[i] = decoded.charCodeAt(i);
                }
                const blob = new Blob([ab], { type });
                const height = img.height;
                const width = img.width;
                setUnglitchedImgDimensions([width, height]);
                axios
                  .post(
                    'https://f2xv5r8ih1.execute-api.us-east-1.amazonaws.com/prod/rgb',
                    blob,
                    { headers: { 'Content-Type': type } },
                  )
                  .then(({ data }: { data: any }) => {
                    console.log('setting unglitched img', data);
                    setUnglitchedImg(data);
                  })
                  .catch(console.error);
              };
            };
          }}
          previewWidth={300}
        />
      )}
      {decayedImage && !loading && (
        <img
          alt="glitched version"
          className="decayed-img"
          style={{
            boxShadow: `0 0 20px rgba(${mainColor}, 0.4)`,
          }}
          src={`data:image/jpg;base64,${decayedImage}`}
        />
      )}
      {unglitchedImg && unglitchedImgDimensions && !decayedImage && (
        <div
          style={{
            width: unglitchedImgDimensions[0] + 6,
            height: unglitchedImgDimensions[1] + 6,
            display: 'inline-block',
            margin: 'auto',
            borderRadius: 10,
            overflow: 'hidden',
          }}
        >
          <Crop
            src={`data:image/jpg;base64,${unglitchedImg}`}
            // src={unglitchedImg.objectURL}
            cropperRef={cropperRef}
          />
        </div>
      )}
      {!loading && !decayedImage && unglitchedImg && (
        <Button label="upload" onClick={upload} />
      )}
      {loading && <ProgressSpinner />}
      {/* {pictureInfo?.colors && (
        <div className='color-grid'>
          {pictureInfo.colors.img_colors.map((img: any) => {
            const colors = img.colorCode
              .replace(/\)|\(/g, '')
              .split(', ')
              .map((c: string) => parseInt(c, 10));
            const hsl = rgbToHsl(colors[0], colors[1], colors[2]);
            return (
              <div
                style={{
                  width: `${img.percent}%`,
                  // backgroundColor: `rgb${img.colorCode}`,
                  backgroundColor: `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`,
                  overflow: 'hidden',
                  color: 'black',
                }}
              >
                {`hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`}
              </div>
            );
          })}
        </div>
      )} */}
      {/* {pictureInfo?.labels && (
        <div>
          {pictureInfo.labels.map((word: string) => (
            <p key={word}>{word}</p>
          ))}
        </div>
      )} */}

      {/* {!user && (
        <div id='login'>
          <h1>login pls</h1>
          <button id='login-button' onClick={redirectToSpotifyAuthorize}>
            Log in with Spotify
          </button>
        </div>
      )} */}
      {songs.length > 0 &&
        [
          {
            track: 'Track',
            artist: 'Artist',
            album: 'Album',
            url: '',
            img: '',
            id: '',
            preview: '',
          },
          ...songs,
        ].map((song, idx) => (
          <Song
            key={song.id}
            song={song}
            idx={idx}
            playingSong={playingSong}
            hoveredSong={hoveredSong}
            hoverOverSong={hoverOverSong}
            playSong={playSong}
            // style={{ filter: `blur(${Math.random() * 1}px)` }}
          />
        ))}
      {/* {user && (
        <button id='logout-button' onClick={logoutClick}>
          Log out
        </button>
      )} */}
    </div>
  );
};
