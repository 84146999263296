import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import { Home } from './Home';
import { SiteWrapper } from './components/SiteWrapper';

import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';

const App = () => {
  const router = createBrowserRouter(
    [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/:playlist',
        element: <Home />,
      },
    ].map((route) => ({
      ...route,
      element: <SiteWrapper key={route.path}>{route.element}</SiteWrapper>,
    }))
  );
  return (
    <PrimeReactProvider>
      <div className='App'>
        <div id='effect' />
        <RouterProvider router={router} />
      </div>
    </PrimeReactProvider>
  );
};

export default App;
