import { Link } from 'react-router-dom';
import logo from './logo.gif';
import './SiteWrapper.css';

export const SiteWrapper = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="grid wrapper">
      <div className="content" style={{ padding: 0 }}>
        <Link to="/" className="head no-select">
          <h1 style={{ margin: 0 }}>
            <img
              id="logo"
              src={logo}
              alt="logo"
              style={{ filter: 'url(#noise)' }}
            />
            decaylist
          </h1>
        </Link>
        {children}

        <div className="footer">
          <span>
            &copy;{' '}
            <a
              href="https://meltknuckles.info/"
              target="_blank"
              rel="noreferrer"
            >
              meltknuckles
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};
